import React from 'react';
import '../../pages/Home/home.scss'
import Header from '../Layout/Header/Header';
import Home from '../../pages/Home/Home';

export default function Master() {
  return (
    <>
      <Home/>
     </>

  )
}
